import { default as abuseReports1GlEmrpaWRMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93VY34FP1Qg4Meta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as index1csDzPRidFMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/index.vue?macro=true";
import { default as moderationmSk4mhKOAaMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/moderation.vue?macro=true";
import { default as pendingBansGIhqKQY7VJMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/pendingBans.vue?macro=true";
import { default as profiles3SlzsWquEzMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/profiles.vue?macro=true";
import { default as expenses5sslVnGs1DMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexNCjIUCuIkeMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewYlgCW7fWBaMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingUT7eIcBA20Meta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingHpn9t2g3NxMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/translations/missing.vue?macro=true";
import { default as usersyeefCcTuLuMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/users.vue?macro=true";
import { default as apiaE4ov2aDVjMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/api.vue?macro=true";
import { default as _91slug_93ew29dlV06xMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/blog/[slug].vue?macro=true";
import { default as index35G5khUvrUMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93Zt3BV99Up3Meta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93VHo5iStOKQMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminl0j39NRpUOMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/census/admin.vue?macro=true";
import { default as indexqt4sAyRx1pMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/census/index.vue?macro=true";
import { default as contactuMKgwfJHuOMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/contact.vue?macro=true";
import { default as designvW7oTswoBvMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/design.vue?macro=true";
import { default as englishSrhXmsXPiGMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/english.vue?macro=true";
import { default as faq8X2EBwI7dSMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/faq.vue?macro=true";
import { default as inclusivePhkmGYp8G3Meta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/inclusive.vue?macro=true";
import { default as indexkj5RPLJbZHMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/index.vue?macro=true";
import { default as licenseALd7XkOraqMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/license.vue?macro=true";
import { default as academicYuZQZBiGEQMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/links/academic.vue?macro=true";
import { default as indexlYDLGJb5LgMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/links/index.vue?macro=true";
import { default as mediaofCWsQ2ZTLMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/links/media.vue?macro=true";
import { default as translinguisticsk6cP4VE1k2Meta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/links/translinguistics.vue?macro=true";
import { default as zineWGbEqIzMSRMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/links/zine.vue?macro=true";
import { default as namesnRqThXcwPAMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/names.vue?macro=true";
import { default as indexCTwYZ5vgmNMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/nouns/index.vue?macro=true";
import { default as templates3XFggjIna4Meta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/nouns/templates.vue?macro=true";
import { default as peoplewxhThpnwc6Meta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/people.vue?macro=true";
import { default as privacylJkkc4ks6IMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/privacy.vue?macro=true";
import { default as _91username_93jz00nRsW1jMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_936aybAp7UNPMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/profile/card-[username].vue?macro=true";
import { default as editorIl7q3n7hFtMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/profile/editor.vue?macro=true";
import { default as anyB0Xn1WgmFRMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/pronouns/any.vue?macro=true";
import { default as askDQ1AmYF8rmMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/pronouns/ask.vue?macro=true";
import { default as avoiding3EdHYbJddXMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/pronouns/avoiding.vue?macro=true";
import { default as indexdFdABibYMGMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/pronouns/index.vue?macro=true";
import { default as mirrorOglaDOQFvYMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/pronouns/mirror.vue?macro=true";
import { default as pronounHezoPbY35PMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesRdiPOsp8C2Meta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/sources.vue?macro=true";
import { default as teamQV6HwnRgCNMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/team.vue?macro=true";
import { default as terminologyMJ6xNxKlOPMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/terminology.vue?macro=true";
import { default as termsCfec20cvdpMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/terms.vue?macro=true";
import { default as userjfHryaCSSAMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/user.vue?macro=true";
import { default as workshopsmU9MTpprEwMeta } from "/home/admin/www/pronoms.fr/release/20241111220617/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReports1GlEmrpaWRMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansGIhqKQY7VJMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiaE4ov2aDVjMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93ew29dlV06xMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: index35G5khUvrUMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93Zt3BV99Up3Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93VHo5iStOKQMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminl0j39NRpUOMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexqt4sAyRx1pMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactuMKgwfJHuOMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishSrhXmsXPiGMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faq8X2EBwI7dSMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivePhkmGYp8G3Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicYuZQZBiGEQMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexlYDLGJb5LgMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaofCWsQ2ZTLMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsk6cP4VE1k2Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineWGbEqIzMSRMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesnRqThXcwPAMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexCTwYZ5vgmNMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templates3XFggjIna4Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peoplewxhThpnwc6Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacylJkkc4ks6IMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93jz00nRsW1jMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_936aybAp7UNPMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorIl7q3n7hFtMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyB0Xn1WgmFRMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askDQ1AmYF8rmMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoiding3EdHYbJddXMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexdFdABibYMGMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorOglaDOQFvYMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounHezoPbY35PMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesRdiPOsp8C2Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamQV6HwnRgCNMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyMJ6xNxKlOPMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsCfec20cvdpMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userjfHryaCSSAMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsmU9MTpprEwMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20241111220617/pages/workshops.vue")
  }
]