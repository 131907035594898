import routerOptions0 from "/home/admin/www/pronoms.fr/release/20241111220617/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_esl_lv5wulkpbkswkjq5vdqjtqgdqe/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/admin/www/pronoms.fr/release/20241111220617/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}