import revive_payload_client_DQ8Ep8C470 from "/home/admin/www/pronoms.fr/release/20241111220617/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_esl_lv5wulkpbkswkjq5vdqjtqgdqe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_d8zCeYiIyN from "/home/admin/www/pronoms.fr/release/20241111220617/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_esl_lv5wulkpbkswkjq5vdqjtqgdqe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_s6CjKtPTNL from "/home/admin/www/pronoms.fr/release/20241111220617/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_esl_lv5wulkpbkswkjq5vdqjtqgdqe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_vFySdCv7n6 from "/home/admin/www/pronoms.fr/release/20241111220617/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_esl_lv5wulkpbkswkjq5vdqjtqgdqe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XFqP6Ywlex from "/home/admin/www/pronoms.fr/release/20241111220617/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_esl_lv5wulkpbkswkjq5vdqjtqgdqe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Alf7uN1Msy from "/home/admin/www/pronoms.fr/release/20241111220617/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_esl_lv5wulkpbkswkjq5vdqjtqgdqe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_AJAErQbt7O from "/home/admin/www/pronoms.fr/release/20241111220617/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_esl_lv5wulkpbkswkjq5vdqjtqgdqe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZxzLjBbzVX from "/home/admin/www/pronoms.fr/release/20241111220617/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.2_typescript@5.6.2_vue@3.5.1_wkanky2ovx5eosqgihc4afoeju/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/admin/www/pronoms.fr/release/20241111220617/.nuxt/components.plugin.mjs";
import prefetch_client_pF9HQrZU50 from "/home/admin/www/pronoms.fr/release/20241111220617/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_esl_lv5wulkpbkswkjq5vdqjtqgdqe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/admin/www/pronoms.fr/release/20241111220617/.nuxt/pwa-icons-plugin.ts";
import pwa_client_qpd293KOFV from "/home/admin/www/pronoms.fr/release/20241111220617/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.5_rollup@4.22.2_vite@5.4.7_@types+node_vbx2mnljdocrf2r7yw5ogrk2ca/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_client_IxKD5BnfFA from "/home/admin/www/pronoms.fr/release/20241111220617/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.5_rollup@4.22.2_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import auth_vT9JWWT9pN from "/home/admin/www/pronoms.fr/release/20241111220617/plugins/auth.ts";
import browserDetect_3Y4e6ym8wL from "/home/admin/www/pronoms.fr/release/20241111220617/plugins/browserDetect.ts";
import globals_qEnHRCYwqu from "/home/admin/www/pronoms.fr/release/20241111220617/plugins/globals.ts";
import polyfill_client_aOiFD9Uk19 from "/home/admin/www/pronoms.fr/release/20241111220617/plugins/polyfill.client.ts";
import sentry_client_shVUlIjFLk from "/home/admin/www/pronoms.fr/release/20241111220617/plugins/sentry.client.ts";
import track_client_qrg1Mffzr3 from "/home/admin/www/pronoms.fr/release/20241111220617/plugins/track.client.ts";
import plugin_auto_pageviews_client_hBGNW279vg from "/home/admin/www/pronoms.fr/release/20241111220617/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.5_rollup@4.22.2_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_DQ8Ep8C470,
  unhead_d8zCeYiIyN,
  router_s6CjKtPTNL,
  payload_client_vFySdCv7n6,
  navigation_repaint_client_XFqP6Ywlex,
  check_outdated_build_client_Alf7uN1Msy,
  chunk_reload_client_AJAErQbt7O,
  plugin_vue3_ZxzLjBbzVX,
  components_plugin_KR1HBZs4kY,
  prefetch_client_pF9HQrZU50,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_qpd293KOFV,
  plugin_client_IxKD5BnfFA,
  auth_vT9JWWT9pN,
  browserDetect_3Y4e6ym8wL,
  globals_qEnHRCYwqu,
  polyfill_client_aOiFD9Uk19,
  sentry_client_shVUlIjFLk,
  track_client_qrg1Mffzr3,
  plugin_auto_pageviews_client_hBGNW279vg
]