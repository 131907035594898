export default [
    'pronoun_subject',
    'pronoun_object',
    'pronoun_disjunctive',
    'pronoun_possessive',
    'inflection_l',
    'inflection_x',
    'inflection_e',
    'inflection_n',
] as const;
